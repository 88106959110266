@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Solid State by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body {
		background-color:		_palette(bg);
		background-image:		url('../../images/bg.jpg');
		background-size:		cover;
		background-attachment:	fixed;
		background-position:	center;

		&:before {
			background: transparentize(_palette(bg), 0.2);
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
		}

		> * {
			position: relative;
			z-index: 1;
		}
	}

/* Features */

	.features {
		article {
			display: inline-block;
			width: 45%;
		}
	}

/* Menu */

	#menu {
		.inner {
			margin: 4em auto;
		}
	}

/* Wrapper */

	#wrapper {
		> header {
			background: none !important;
		}
	}

	.wrapper {
		&.spotlight {
			.inner {
				text-align: left !important;
			}

			.image {
				display: inline-block;
				margin: 0 _size(section-spacing, large) _size(element-margin) 0 !important;
				vertical-align: middle;
				width: 24%;
			}

			.content {
				display: inline-block;
				vertical-align: middle;
				width: 70%;
			}
		}
	}

/* Banner */

	#banner {
		background: none !important;
	}

/* Footer */

	#footer {
		background: none !important;

		.inner {
			form {
				display: inline-block;
				width: 45%;
			}

			.contact {
				display: inline-block;
				width: 45%;
			}
		}
	}