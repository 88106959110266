// Misc.
	$misc: (
		z-index-base:			10000,
		max-wrapper-styles:		6
	);

// Duration.
	$duration: (
		menu:					0.35s,
		transition:				0.2s
	);

// Size.
	$size: (
		border-radius:			5px,
		element-height:			2.75em,
		element-margin:			2em,
		inner:					55em,
		section-spacing:		(
			large:				3em,
			medium:				2em,
			small:				1.75em
		),
		wrapper-edges:			(
			large:				6.5em,
			medium:				4.75em,
			small:				2.5em
		)
	);

// Font.
	$font: (
		family:					('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:			('Courier New', monospace),
		family-heading:			(Raleway, Helvetica, sans-serif),
		weight:					300,
		weight-bold:			600,
		weight-heading:			200,
		weight-heading-bold:	700,
		kern-heading:			0.1em
	);

// Palette.
	$palette: (
		bg:						#4c4c4c,
		fg:						#ffffff,
		fg-bold:				#ffffff,
		fg-light:				rgba(255,255,255,0.35),
		border:					rgba(255,255,255,0.125),
		border-bg:				rgba(255,255,255,0.025),
		border2:				rgba(255,255,255,0.25),
		border2-bg:				rgba(255,255,255,0.075),
		accent:					#9b1e1d
	);